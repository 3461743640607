<template>
  <section class="services-section">
    <div class="container">
      <div class="services-section__inner">
        <div class="services-list">
          <div class="services__list-item services__list-item1 services__list-item--hide" />
          <div class="services__list-item padding-left">
            <p class="benefits__list-title main__title">
              Техосмотр автотранспорта
            </p>
            <p class="benefits__list-descr main__text">
              Испытание колесных транспортных средств на станции технического контроля ТЭЦ — это
              экспертная диагностика специализированной техникой и компетентная оценка реальное состояние
              конкретного автомобиля в соответствии с требованиями международных стандартов.
            </p>
            <div class="btn-right">
              <router-link
                :to="{ name:'LightCarPage' }"
                class="btn btn--main"
              >
                <div class="services__item-content">
                  <p class="services__btn-title">
                    Подробнее
                  </p>
                  <img
                    src="@/assets/images/arrow-black.svg"
                    alt="Arrow"
                    class="services-img"
                  >
                </div>
              </router-link>
            </div>
          </div>
          <div class="services__list-item padding-rigth">
            <p class="benefits__list-title main__title">
              Техосмотры для грузового транспорта
            </p>
            <p class="benefits__list-descr main__text">
              Согласно закону Украины об утвержденных правилах дорожного движения, грузовым автомобилям
              обязательно прохождение технического контроля ежегодно. Мы предоставляем высококачественные
              услуги по проверке грузовых авто различных категорий.
            </p>
            <router-link
              :to="{ name:'HeavyCarPage' }"
              class="btn btn--main"
            >
              <div class="services__item-content">
                <p class="services__btn-title">
                  Подробнее
                </p>
                <img
                  src="@/assets/images/arrow-black.svg"
                  alt="Arrow"
                  class="services-img"
                >
              </div>
            </router-link>
          </div>
          <div class="services__list-item services__list-item2 services__list-item--hide" />
          <div class="services__list-item services__list-item3 services__list-item--hide" />
          <div class="services__list-item padding-left">
            <p class="benefits__list-title main__title">
              Контроль водного транспорта
            </p>
            <p class="benefits__list-descr main__text">
              Технические осмотры яхт, лодок, водных мотоциклов и других маломерных судов. Обязательный
              ежегодный контроль технических составляющих судов, сертификация, сюрвей водных частей
              крупногабаритных пассажирских и торговых лайнеров.
            </p>
            <div class="btn-right">
              <router-link
                :to="{ name:'BoatsPage' }"
                class="btn btn--main"
              >
                <div class="services__item-content">
                  <p class="services__btn-title">
                    Подробнее
                  </p>
                  <img
                    src="@/assets/images/arrow-black.svg"
                    alt="Arrow"
                    class="services-img"
                  >
                </div>
              </router-link>
            </div>
          </div>
          <div class="services__list-item padding-rigth">
            <p class="services-title main__title">
              Проверка гидротехнических сооружений
            </p>
            <p class="benefits__list-descr main__text">
              Проведение общеобязательных осмотров акваторий портов, причалов, водных частей мостов,
              бетонно-водных конструкций подводными дронами. Исключительные протоколы проведения проверок
              с точным заключением экспертов
            </p>
            <router-link
              :to="{ name:'BuildingsPage' }"
              class="btn btn--main"
            >
              <div class="services__item-content">
                <p class="services__btn-title">
                  Подробнее
                </p>
                <img
                  src="@/assets/images/arrow-black.svg"
                  alt="Arrow"
                  class="services-img"
                >
              </div>
            </router-link>
          </div>
          <div class="services__list-item services__list-item4 services__list-item--hide" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'ServicesList'
}
</script>
